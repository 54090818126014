.homeGrid
{
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  margin: 0 auto; max-width: 1280px; 
  grid-column-gap: 50px;
  padding-top: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

.mintedText{
  font-size: 1.875em;
}