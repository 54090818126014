a:link { text-decoration: none; }

a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }

.linkcolor {
    color: rgb(224, 224, 224);
  }