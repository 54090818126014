:root {
    --main-light-color: #f9bbb1;
    --main-dark-color: #2e3536;
    --main-background-color: black;
  }
  
  body{
      background-color: var(--main-background-color);
  }
  
.form-control:not(active){

    color: white;
}

.form-control:focus{
    color: white;
    background-color: #212529;
}

.form-control {

    border-radius: 0;
    background-color: #212529;

}
  
  .center{
      padding-top: 50%;
  }
  
  .dialog-1{
        height: 30%;
        width: 60%;
        background-color: var(--main-dark-color);
        position: absolute;
        border-radius: 10%;
  }

  .form-dialog{
    height: 20%;
    width: 70%;
    background-color: var(--main-dark-color);
    margin: 0 auto;
    border-radius: 10%;
}
  
  .left-point{
      width: 0;
      height: 0;
      border-left: 2vh solid transparent;
      border-right: 2vh solid transparent;
      border-top: 10vh solid var(--main-dark-color);
      position: absolute;
      top: 45%;
      left: -10%;
      transform: rotate(60deg);
  }
  
.mint-buttons
{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 0 auto;
  grid-column-gap: 50px;
  padding-top: 30px;

}