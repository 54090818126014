.form-control:not(active){

  color: white;
}

.form-control:focus{
  color: white;
  background-color: #212529;
}


.invalid-feedback {
  display: block;
}


.form-control {

  border-radius: 0;
  background-color: #212529;

}

.color-buttons
{
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  grid-column-gap: 50px;
  padding-top: 30px;
  align-items: end;
}

.update-buttons
{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0 auto;
  grid-column-gap: 50px;
  padding-top: 30px;
  align-items: end;
}

.colorbutton{
  width: 100px;

}

label{
  color: #f3f3f3;
  font-size: large;
  font-weight: bold;
}

.colorGrid
{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0 auto; max-width: 1280px; 
  grid-column-gap: 50px;
  padding-top: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

.mintedText{
  font-size: 1.875em;
}